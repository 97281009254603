export const TELEPHONE_CODE_93 = '+93'
export const TELEPHONE_CODE_355 = '+355'
export const TELEPHONE_CODE_213 = '+213'
export const TELEPHONE_CODE_684 = '+684'
export const TELEPHONE_CODE_376 = '+376'
export const TELEPHONE_CODE_244 = '+244'
export const TELEPHONE_CODE_1264 = '+1264'
export const TELEPHONE_CODE_1268 = '+1268'
export const TELEPHONE_CODE_54 = '+54'
export const TELEPHONE_CODE_374 = '+374'
export const TELEPHONE_CODE_297 = '+297'
export const TELEPHONE_CODE_247 = '+247'
export const TELEPHONE_CODE_61 = '+61'
export const TELEPHONE_CODE_672 = '+672'
export const TELEPHONE_CODE_43 = '+43'
export const TELEPHONE_CODE_994 = '+994'
export const TELEPHONE_CODE_1242 = '+1242'
export const TELEPHONE_CODE_973 = '+973'
export const TELEPHONE_CODE_880 = '+880'
export const TELEPHONE_CODE_1246 = '+1246'
export const TELEPHONE_CODE_375 = '+375'
export const TELEPHONE_CODE_32 = '+32'
export const TELEPHONE_CODE_501 = '+501'
export const TELEPHONE_CODE_229 = '+229'
export const TELEPHONE_CODE_1441 = '+1441'
export const TELEPHONE_CODE_975 = '+975'
export const TELEPHONE_CODE_591 = '+591'
export const TELEPHONE_CODE_387 = '+387'
export const TELEPHONE_CODE_267 = '+267'
export const TELEPHONE_CODE_55 = '+55'
export const TELEPHONE_CODE_1284 = '+1284'
export const TELEPHONE_CODE_673 = '+673'
export const TELEPHONE_CODE_359 = '+359'
export const TELEPHONE_CODE_226 = '+226'
export const TELEPHONE_CODE_257 = '+257'
export const TELEPHONE_CODE_855 = '+855'
export const TELEPHONE_CODE_237 = '+237'
export const TELEPHONE_CODE_1 = '+1'
export const TELEPHONE_CODE_238 = '+238'
export const TELEPHONE_CODE_1345 = '+1345'
export const TELEPHONE_CODE_236 = '+236'
export const TELEPHONE_CODE_235 = '+235'
export const TELEPHONE_CODE_56 = '+56'
export const TELEPHONE_CODE_86 = '+86'
export const TELEPHONE_CODE_57 = '+57'
export const TELEPHONE_CODE_269 = '+269'
export const TELEPHONE_CODE_242 = '+242'
export const TELEPHONE_CODE_243 = '+243'
export const TELEPHONE_CODE_682 = '+682'
export const TELEPHONE_CODE_506 = '+506'
export const TELEPHONE_CODE_225 = '+225'
export const TELEPHONE_CODE_385 = '+385'
export const TELEPHONE_CODE_53 = '+53'
export const TELEPHONE_CODE_5399 = '+5399'
export const TELEPHONE_CODE_357 = '+357'
export const TELEPHONE_CODE_420 = '+420'
export const TELEPHONE_CODE_45 = '+45'
export const TELEPHONE_CODE_246 = '+246'
export const TELEPHONE_CODE_253 = '+253'
export const TELEPHONE_CODE_1767 = '+1767'
export const TELEPHONE_CODE_1809 = '+1809'
export const TELEPHONE_CODE_670 = '+670'
export const TELEPHONE_CODE_593 = '+593'
export const TELEPHONE_CODE_20 = '+20'
export const TELEPHONE_CODE_503 = '+503'
export const TELEPHONE_CODE_88213 = '+88213'
export const TELEPHONE_CODE_240 = '+240'
export const TELEPHONE_CODE_291 = '+291'
export const TELEPHONE_CODE_372 = '+372'
export const TELEPHONE_CODE_251 = '+251'
export const TELEPHONE_CODE_500 = '+500'
export const TELEPHONE_CODE_298 = '+298'
export const TELEPHONE_CODE_679 = '+679'
export const TELEPHONE_CODE_358 = '+358'
export const TELEPHONE_CODE_33 = '+33'
export const TELEPHONE_CODE_594 = '+594'
export const TELEPHONE_CODE_689 = '+689'
export const TELEPHONE_CODE_241 = '+241'
export const TELEPHONE_CODE_220 = '+220'
export const TELEPHONE_CODE_995 = '+995'
export const TELEPHONE_CODE_49 = '+49'
export const TELEPHONE_CODE_233 = '+233'
export const TELEPHONE_CODE_350 = '+350'
export const TELEPHONE_CODE_30 = '+30'
export const TELEPHONE_CODE_299 = '+299'
export const TELEPHONE_CODE_1473 = '+1473'
export const TELEPHONE_CODE_590 = '+590'
export const TELEPHONE_CODE_1671 = '+1671'
export const TELEPHONE_CODE_502 = '+502'
export const TELEPHONE_CODE_245 = '+245'
export const TELEPHONE_CODE_224 = '+224'
export const TELEPHONE_CODE_592 = '+592'
export const TELEPHONE_CODE_509 = '+509'
export const TELEPHONE_CODE_504 = '+504'
export const TELEPHONE_CODE_852 = '+852'
export const TELEPHONE_CODE_36 = '+36'
export const TELEPHONE_CODE_91 = '+91'
export const TELEPHONE_CODE_62 = '+62'
export const TELEPHONE_CODE_871 = '+871'
export const TELEPHONE_CODE_874 = '+874'
export const TELEPHONE_CODE_873 = '+873'
export const TELEPHONE_CODE_872 = '+872'
export const TELEPHONE_CODE_98 = '+98'
export const TELEPHONE_CODE_964 = '+964'
export const TELEPHONE_CODE_353 = '+353'
export const TELEPHONE_CODE_8816 = '+8816'
export const TELEPHONE_CODE_972 = '+972'
export const TELEPHONE_CODE_39 = '+39'
export const TELEPHONE_CODE_1876 = '+1876'
export const TELEPHONE_CODE_81 = '+81'
export const TELEPHONE_CODE_962 = '+962'
export const TELEPHONE_CODE_7 = '+7'
export const TELEPHONE_CODE_254 = '+254'
export const TELEPHONE_CODE_686 = '+686'
export const TELEPHONE_CODE_850 = '+850'
export const TELEPHONE_CODE_82 = '+82'
export const TELEPHONE_CODE_965 = '+965'
export const TELEPHONE_CODE_996 = '+996'
export const TELEPHONE_CODE_856 = '+856'
export const TELEPHONE_CODE_371 = '+371'
export const TELEPHONE_CODE_961 = '+961'
export const TELEPHONE_CODE_266 = '+266'
export const TELEPHONE_CODE_231 = '+231'
export const TELEPHONE_CODE_218 = '+218'
export const TELEPHONE_CODE_423 = '+423'
export const TELEPHONE_CODE_370 = '+370'
export const TELEPHONE_CODE_352 = '+352'
export const TELEPHONE_CODE_853 = '+853'
export const TELEPHONE_CODE_389 = '+389'
export const TELEPHONE_CODE_261 = '+261'
export const TELEPHONE_CODE_265 = '+265'
export const TELEPHONE_CODE_60 = '+60'
export const TELEPHONE_CODE_960 = '+960'
export const TELEPHONE_CODE_223 = '+223'
export const TELEPHONE_CODE_356 = '+356'
export const TELEPHONE_CODE_692 = '+692'
export const TELEPHONE_CODE_596 = '+596'
export const TELEPHONE_CODE_222 = '+222'
export const TELEPHONE_CODE_230 = '+230'
export const TELEPHONE_CODE_52 = '+52'
export const TELEPHONE_CODE_691 = '+691'
export const TELEPHONE_CODE_373 = '+373'
export const TELEPHONE_CODE_377 = '+377'
export const TELEPHONE_CODE_976 = '+976'
export const TELEPHONE_CODE_1664 = '+1664'
export const TELEPHONE_CODE_212 = '+212'
export const TELEPHONE_CODE_258 = '+258'
export const TELEPHONE_CODE_95 = '+95'
export const TELEPHONE_CODE_264 = '+264'
export const TELEPHONE_CODE_674 = '+674'
export const TELEPHONE_CODE_977 = '+977'
export const TELEPHONE_CODE_31 = '+31'
export const TELEPHONE_CODE_599 = '+599'
export const TELEPHONE_CODE_687 = '+687'
export const TELEPHONE_CODE_64 = '+64'
export const TELEPHONE_CODE_505 = '+505'
export const TELEPHONE_CODE_227 = '+227'
export const TELEPHONE_CODE_234 = '+234'
export const TELEPHONE_CODE_683 = '+683'
export const TELEPHONE_CODE_1670 = '+1670'
export const TELEPHONE_CODE_47 = '+47'
export const TELEPHONE_CODE_968 = '+968'
export const TELEPHONE_CODE_92 = '+92'
export const TELEPHONE_CODE_680 = '+680'
export const TELEPHONE_CODE_507 = '+507'
export const TELEPHONE_CODE_675 = '+675'
export const TELEPHONE_CODE_595 = '+595'
export const TELEPHONE_CODE_51 = '+51'
export const TELEPHONE_CODE_63 = '+63'
export const TELEPHONE_CODE_48 = '+48'
export const TELEPHONE_CODE_351 = '+351'
export const TELEPHONE_CODE_1939 = '+1939'
export const TELEPHONE_CODE_974 = '+974'
export const TELEPHONE_CODE_262 = '+262'
export const TELEPHONE_CODE_40 = '+40'
export const TELEPHONE_CODE_250 = '+250'
export const TELEPHONE_CODE_290 = '+290'
export const TELEPHONE_CODE_1869 = '+1869'
export const TELEPHONE_CODE_1758 = '+1758'
export const TELEPHONE_CODE_508 = '+508'
export const TELEPHONE_CODE_1784 = '+1784'
export const TELEPHONE_CODE_378 = '+378'
export const TELEPHONE_CODE_239 = '+239'
export const TELEPHONE_CODE_966 = '+966'
export const TELEPHONE_CODE_221 = '+221'
export const TELEPHONE_CODE_381 = '+381'
export const TELEPHONE_CODE_248 = '+248'
export const TELEPHONE_CODE_232 = '+232'
export const TELEPHONE_CODE_65 = '+65'
export const TELEPHONE_CODE_421 = '+421'
export const TELEPHONE_CODE_386 = '+386'
export const TELEPHONE_CODE_677 = '+677'
export const TELEPHONE_CODE_252 = '+252'
export const TELEPHONE_CODE_27 = '+27'
export const TELEPHONE_CODE_34 = '+34'
export const TELEPHONE_CODE_94 = '+94'
export const TELEPHONE_CODE_249 = '+249'
export const TELEPHONE_CODE_597 = '+597'
export const TELEPHONE_CODE_268 = '+268'
export const TELEPHONE_CODE_46 = '+46'
export const TELEPHONE_CODE_41 = '+41'
export const TELEPHONE_CODE_963 = '+963'
export const TELEPHONE_CODE_886 = '+886'
export const TELEPHONE_CODE_992 = '+992'
export const TELEPHONE_CODE_255 = '+255'
export const TELEPHONE_CODE_66 = '+66'
export const TELEPHONE_CODE_228 = '+228'
export const TELEPHONE_CODE_690 = '+690'
export const TELEPHONE_CODE_676 = '+676'
export const TELEPHONE_CODE_1868 = '+1868'
export const TELEPHONE_CODE_216 = '+216'
export const TELEPHONE_CODE_90 = '+90'
export const TELEPHONE_CODE_993 = '+993'
export const TELEPHONE_CODE_1649 = '+1649'
export const TELEPHONE_CODE_688 = '+688'
export const TELEPHONE_CODE_256 = '+256'
export const TELEPHONE_CODE_380 = '+380'
export const TELEPHONE_CODE_971 = '+971'
export const TELEPHONE_CODE_44 = '+44'
export const TELEPHONE_CODE_1340 = '+1340'
export const TELEPHONE_CODE_598 = '+598'
export const TELEPHONE_CODE_998 = '+998'
export const TELEPHONE_CODE_678 = '+678'
export const TELEPHONE_CODE_58 = '+58'
export const TELEPHONE_CODE_84 = '+84'
export const TELEPHONE_CODE_681 = '+681'
export const TELEPHONE_CODE_685 = '+685'
export const TELEPHONE_CODE_967 = '+967'
export const TELEPHONE_CODE_260 = '+260'
export const TELEPHONE_CODE_263 = '+263'
export const TELEPHONE_CODE_354 = '+354'
export const TELEPHONE_CODE_8817 = '+8817'
export const TELEPHONE_CODE_1787 = '+1787'
export const TELEPHONE_CODE_0 = '0'
export const TELEPHONE_CODE_379 = '+379'
export const TELEPHONE_CODE_211 = '+211'
export const TELEPHONE_CODE_382 = '+382'
export const TELEPHONE_CODE_383 = '+383'
export const TELEPHONE_CODE_970 = '+970'
export const ALL_TELEPHONE_CODES = [
  TELEPHONE_CODE_93,
  TELEPHONE_CODE_355,
  TELEPHONE_CODE_213,
  TELEPHONE_CODE_684,
  TELEPHONE_CODE_376,
  TELEPHONE_CODE_244,
  TELEPHONE_CODE_1264,
  TELEPHONE_CODE_1268,
  TELEPHONE_CODE_54,
  TELEPHONE_CODE_374,
  TELEPHONE_CODE_297,
  TELEPHONE_CODE_247,
  TELEPHONE_CODE_61,
  TELEPHONE_CODE_672,
  TELEPHONE_CODE_43,
  TELEPHONE_CODE_994,
  TELEPHONE_CODE_1242,
  TELEPHONE_CODE_973,
  TELEPHONE_CODE_880,
  TELEPHONE_CODE_1246,
  TELEPHONE_CODE_375,
  TELEPHONE_CODE_32,
  TELEPHONE_CODE_501,
  TELEPHONE_CODE_229,
  TELEPHONE_CODE_1441,
  TELEPHONE_CODE_975,
  TELEPHONE_CODE_591,
  TELEPHONE_CODE_387,
  TELEPHONE_CODE_267,
  TELEPHONE_CODE_55,
  TELEPHONE_CODE_1284,
  TELEPHONE_CODE_673,
  TELEPHONE_CODE_359,
  TELEPHONE_CODE_226,
  TELEPHONE_CODE_257,
  TELEPHONE_CODE_855,
  TELEPHONE_CODE_237,
  TELEPHONE_CODE_1,
  TELEPHONE_CODE_238,
  TELEPHONE_CODE_1345,
  TELEPHONE_CODE_236,
  TELEPHONE_CODE_235,
  TELEPHONE_CODE_56,
  TELEPHONE_CODE_86,
  TELEPHONE_CODE_57,
  TELEPHONE_CODE_269,
  TELEPHONE_CODE_242,
  TELEPHONE_CODE_243,
  TELEPHONE_CODE_682,
  TELEPHONE_CODE_506,
  TELEPHONE_CODE_225,
  TELEPHONE_CODE_385,
  TELEPHONE_CODE_53,
  TELEPHONE_CODE_5399,
  TELEPHONE_CODE_357,
  TELEPHONE_CODE_420,
  TELEPHONE_CODE_45,
  TELEPHONE_CODE_246,
  TELEPHONE_CODE_253,
  TELEPHONE_CODE_1767,
  TELEPHONE_CODE_1809,
  TELEPHONE_CODE_670,
  TELEPHONE_CODE_593,
  TELEPHONE_CODE_20,
  TELEPHONE_CODE_503,
  TELEPHONE_CODE_88213,
  TELEPHONE_CODE_240,
  TELEPHONE_CODE_291,
  TELEPHONE_CODE_372,
  TELEPHONE_CODE_251,
  TELEPHONE_CODE_500,
  TELEPHONE_CODE_298,
  TELEPHONE_CODE_679,
  TELEPHONE_CODE_358,
  TELEPHONE_CODE_33,
  TELEPHONE_CODE_594,
  TELEPHONE_CODE_689,
  TELEPHONE_CODE_241,
  TELEPHONE_CODE_220,
  TELEPHONE_CODE_995,
  TELEPHONE_CODE_49,
  TELEPHONE_CODE_233,
  TELEPHONE_CODE_350,
  TELEPHONE_CODE_30,
  TELEPHONE_CODE_299,
  TELEPHONE_CODE_1473,
  TELEPHONE_CODE_590,
  TELEPHONE_CODE_1671,
  TELEPHONE_CODE_502,
  TELEPHONE_CODE_245,
  TELEPHONE_CODE_224,
  TELEPHONE_CODE_592,
  TELEPHONE_CODE_509,
  TELEPHONE_CODE_504,
  TELEPHONE_CODE_852,
  TELEPHONE_CODE_36,
  TELEPHONE_CODE_91,
  TELEPHONE_CODE_62,
  TELEPHONE_CODE_871,
  TELEPHONE_CODE_874,
  TELEPHONE_CODE_873,
  TELEPHONE_CODE_872,
  TELEPHONE_CODE_98,
  TELEPHONE_CODE_964,
  TELEPHONE_CODE_353,
  TELEPHONE_CODE_8816,
  TELEPHONE_CODE_972,
  TELEPHONE_CODE_39,
  TELEPHONE_CODE_1876,
  TELEPHONE_CODE_81,
  TELEPHONE_CODE_962,
  TELEPHONE_CODE_7,
  TELEPHONE_CODE_254,
  TELEPHONE_CODE_686,
  TELEPHONE_CODE_850,
  TELEPHONE_CODE_82,
  TELEPHONE_CODE_965,
  TELEPHONE_CODE_996,
  TELEPHONE_CODE_856,
  TELEPHONE_CODE_371,
  TELEPHONE_CODE_961,
  TELEPHONE_CODE_266,
  TELEPHONE_CODE_231,
  TELEPHONE_CODE_218,
  TELEPHONE_CODE_423,
  TELEPHONE_CODE_370,
  TELEPHONE_CODE_352,
  TELEPHONE_CODE_853,
  TELEPHONE_CODE_389,
  TELEPHONE_CODE_261,
  TELEPHONE_CODE_265,
  TELEPHONE_CODE_60,
  TELEPHONE_CODE_960,
  TELEPHONE_CODE_223,
  TELEPHONE_CODE_356,
  TELEPHONE_CODE_692,
  TELEPHONE_CODE_596,
  TELEPHONE_CODE_222,
  TELEPHONE_CODE_230,
  TELEPHONE_CODE_52,
  TELEPHONE_CODE_691,
  TELEPHONE_CODE_373,
  TELEPHONE_CODE_377,
  TELEPHONE_CODE_976,
  TELEPHONE_CODE_1664,
  TELEPHONE_CODE_212,
  TELEPHONE_CODE_258,
  TELEPHONE_CODE_95,
  TELEPHONE_CODE_264,
  TELEPHONE_CODE_674,
  TELEPHONE_CODE_977,
  TELEPHONE_CODE_31,
  TELEPHONE_CODE_599,
  TELEPHONE_CODE_687,
  TELEPHONE_CODE_64,
  TELEPHONE_CODE_505,
  TELEPHONE_CODE_227,
  TELEPHONE_CODE_234,
  TELEPHONE_CODE_683,
  TELEPHONE_CODE_1670,
  TELEPHONE_CODE_47,
  TELEPHONE_CODE_968,
  TELEPHONE_CODE_92,
  TELEPHONE_CODE_680,
  TELEPHONE_CODE_507,
  TELEPHONE_CODE_675,
  TELEPHONE_CODE_595,
  TELEPHONE_CODE_51,
  TELEPHONE_CODE_63,
  TELEPHONE_CODE_48,
  TELEPHONE_CODE_351,
  TELEPHONE_CODE_1939,
  TELEPHONE_CODE_974,
  TELEPHONE_CODE_262,
  TELEPHONE_CODE_40,
  TELEPHONE_CODE_250,
  TELEPHONE_CODE_290,
  TELEPHONE_CODE_1869,
  TELEPHONE_CODE_1758,
  TELEPHONE_CODE_508,
  TELEPHONE_CODE_1784,
  TELEPHONE_CODE_378,
  TELEPHONE_CODE_239,
  TELEPHONE_CODE_966,
  TELEPHONE_CODE_221,
  TELEPHONE_CODE_381,
  TELEPHONE_CODE_248,
  TELEPHONE_CODE_232,
  TELEPHONE_CODE_65,
  TELEPHONE_CODE_421,
  TELEPHONE_CODE_386,
  TELEPHONE_CODE_677,
  TELEPHONE_CODE_252,
  TELEPHONE_CODE_27,
  TELEPHONE_CODE_34,
  TELEPHONE_CODE_94,
  TELEPHONE_CODE_249,
  TELEPHONE_CODE_597,
  TELEPHONE_CODE_268,
  TELEPHONE_CODE_46,
  TELEPHONE_CODE_41,
  TELEPHONE_CODE_963,
  TELEPHONE_CODE_886,
  TELEPHONE_CODE_992,
  TELEPHONE_CODE_255,
  TELEPHONE_CODE_66,
  TELEPHONE_CODE_228,
  TELEPHONE_CODE_690,
  TELEPHONE_CODE_676,
  TELEPHONE_CODE_1868,
  TELEPHONE_CODE_216,
  TELEPHONE_CODE_90,
  TELEPHONE_CODE_993,
  TELEPHONE_CODE_1649,
  TELEPHONE_CODE_688,
  TELEPHONE_CODE_256,
  TELEPHONE_CODE_380,
  TELEPHONE_CODE_971,
  TELEPHONE_CODE_44,
  TELEPHONE_CODE_1340,
  TELEPHONE_CODE_598,
  TELEPHONE_CODE_998,
  TELEPHONE_CODE_678,
  TELEPHONE_CODE_58,
  TELEPHONE_CODE_84,
  TELEPHONE_CODE_681,
  TELEPHONE_CODE_685,
  TELEPHONE_CODE_967,
  TELEPHONE_CODE_260,
  TELEPHONE_CODE_263,
  TELEPHONE_CODE_354,
  TELEPHONE_CODE_8817,
  TELEPHONE_CODE_1787,
  TELEPHONE_CODE_0,
  TELEPHONE_CODE_379,
  TELEPHONE_CODE_211,
  TELEPHONE_CODE_382,
  TELEPHONE_CODE_383,
  TELEPHONE_CODE_970,
] as const

export const DEFAULT_TELEPHONE_CODE = TELEPHONE_CODE_421
