import {If} from 'babel-plugin-jsx-control-statements'
// eslint-disable-next-line import/named
import {Script} from 'gatsby'
import {Helmet} from 'react-helmet'
import {ThemeProvider} from 'uikit'
import ErrorModal from './src/components/ErrorModal'
import {AlertProvider} from './src/hooks/useAlert'
import {CalculateProvider} from './src/hooks/useCalculate'
import {LoadingProvider} from './src/hooks/useLoading'
import '@fontsource-variable/open-sans/wdth.css'
import '@fontsource/ibm-plex-mono'
import {setupAbtests} from './src/utils/abtests'


export const wrapPageElement = ({element}) => (
  <ThemeProvider>
    {element}
    <ErrorModal />
  </ThemeProvider>
)

// eslint-disable-next-line no-process-env
const gtm = process.env.GATSBY_GTM

export const wrapRootElement = ({element}) => {
  return (
    <LoadingProvider>
      <AlertProvider>
        <CalculateProvider>
          <Helmet>
            <script>
              {`
                (function () {
                  const url = new URL('${process.env.GATSBY_SITE_URL /* eslint-disable-line no-process-env */}');
                  if (url.hostname !== window.location.hostname) {
                    window.location.href = url.href;
                  }
                })();
              `.replace(/\s{2,}/g, '')}
            </script>
          </Helmet>
          <If condition={gtm}>
            <Script
                id="_gatsby-gtm-init"
                dangerouslySetInnerHTML={{
                  __html: `
      (function(w,l){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      })(window,'dataLayer');`,
                }}
            />
            <Script id="_gatbsy-gtm" src={`https://www.googletagmanager.com/gtm.js?id=${gtm}`} />
          </If>
          {element}
        </CalculateProvider>
      </AlertProvider>
    </LoadingProvider>
  )
}

if (typeof window !== 'undefined') {
  const abtests = setupAbtests()
  if (abtests) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({abtests})
  }
}

export const onClientEntry = () => {

  const applicationId = process.env.GATSBY_DD_APPLICATION_ID // eslint-disable-line no-process-env
  const clientToken = process.env.GATSBY_DD_CLIENT_TOKEN // eslint-disable-line no-process-env
  if (applicationId && clientToken) {
    import(/* webpackChunkName: "datadog" */ '@datadog/browser-rum').then(({datadogRum}) => {
      datadogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.eu',
        service: 'web',
        // eslint-disable-next-line no-process-env
        env: process.env.GATSBY_DD_ENV || 'staging',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      })

      datadogRum.startSessionReplayRecording()
    })
  }
}
