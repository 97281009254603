exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-comparison-tsx": () => import("./../../../src/templates/comparison.tsx" /* webpackChunkName: "component---src-templates-comparison-tsx" */),
  "component---src-templates-contract-allianz-index-js": () => import("./../../../src/templates/contract/allianz/index.js" /* webpackChunkName: "component---src-templates-contract-allianz-index-js" */),
  "component---src-templates-contract-allianz-new-js": () => import("./../../../src/templates/contract/allianz/new.js" /* webpackChunkName: "component---src-templates-contract-allianz-new-js" */),
  "component---src-templates-contract-csob-index-js": () => import("./../../../src/templates/contract/csob/index.js" /* webpackChunkName: "component---src-templates-contract-csob-index-js" */),
  "component---src-templates-contract-csob-new-js": () => import("./../../../src/templates/contract/csob/new.js" /* webpackChunkName: "component---src-templates-contract-csob-new-js" */),
  "component---src-templates-contract-generali-index-js": () => import("./../../../src/templates/contract/generali/index.js" /* webpackChunkName: "component---src-templates-contract-generali-index-js" */),
  "component---src-templates-contract-generali-new-js": () => import("./../../../src/templates/contract/generali/new.js" /* webpackChunkName: "component---src-templates-contract-generali-new-js" */),
  "component---src-templates-contract-genertel-index-js": () => import("./../../../src/templates/contract/genertel/index.js" /* webpackChunkName: "component---src-templates-contract-genertel-index-js" */),
  "component---src-templates-contract-genertel-new-js": () => import("./../../../src/templates/contract/genertel/new.js" /* webpackChunkName: "component---src-templates-contract-genertel-new-js" */),
  "component---src-templates-contract-komunalna-index-js": () => import("./../../../src/templates/contract/komunalna/index.js" /* webpackChunkName: "component---src-templates-contract-komunalna-index-js" */),
  "component---src-templates-contract-komunalna-new-js": () => import("./../../../src/templates/contract/komunalna/new.js" /* webpackChunkName: "component---src-templates-contract-komunalna-new-js" */),
  "component---src-templates-contract-kooperativa-index-js": () => import("./../../../src/templates/contract/kooperativa/index.js" /* webpackChunkName: "component---src-templates-contract-kooperativa-index-js" */),
  "component---src-templates-contract-kooperativa-new-js": () => import("./../../../src/templates/contract/kooperativa/new.js" /* webpackChunkName: "component---src-templates-contract-kooperativa-new-js" */),
  "component---src-templates-contract-union-index-js": () => import("./../../../src/templates/contract/union/index.js" /* webpackChunkName: "component---src-templates-contract-union-index-js" */),
  "component---src-templates-contract-union-new-js": () => import("./../../../src/templates/contract/union/new.js" /* webpackChunkName: "component---src-templates-contract-union-new-js" */),
  "component---src-templates-contract-wustenrot-index-js": () => import("./../../../src/templates/contract/wustenrot/index.js" /* webpackChunkName: "component---src-templates-contract-wustenrot-index-js" */),
  "component---src-templates-contract-wustenrot-new-js": () => import("./../../../src/templates/contract/wustenrot/new.js" /* webpackChunkName: "component---src-templates-contract-wustenrot-new-js" */),
  "component---src-templates-form-tsx": () => import("./../../../src/templates/form.tsx" /* webpackChunkName: "component---src-templates-form-tsx" */),
  "component---src-templates-insurance-companies-tsx": () => import("./../../../src/templates/insuranceCompanies.tsx" /* webpackChunkName: "component---src-templates-insurance-companies-tsx" */),
  "component---src-templates-insurance-company-tsx": () => import("./../../../src/templates/insuranceCompany.tsx" /* webpackChunkName: "component---src-templates-insurance-company-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

