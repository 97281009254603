export const PRIVATE_PERSON = 'privatePerson'
export const SELF_EMPLOYED = 'selfEmployed'
export const COMPANY = 'company'

export const ALL_LEGAL_FORMS = [
  PRIVATE_PERSON,
  SELF_EMPLOYED,
  COMPANY,
] as const

export type LegalForm = typeof ALL_LEGAL_FORMS[number]
