import {transparentize} from 'polished'


const YELLOW = '#FFE040'
const YELLOW_DARK = '#FFC700'
const YELLOW_LIGHT = '#FFF3B3'
const ORANGE = '#ED9C00'
const ORANGE_2 = '#FFF9EF'
const BLACK = '#000000'
const BLACK_2 = '#12192B'
const BLACK_3 = '#0B101D'
const BLACK_4 = '#080804'
const WHITE = '#FFFFFF'
const BLUE = '#455085'
const BLUE_2 = '#005FA8'
const DARK_BLUE = '#0E131F'
const DARK_BLUE_2 = '#0F1422'
const DARK_BLUE_3 = '#181C2F'
const DARK_BLUE_4 = '#1E2741'
const DARK_BLUE_5 = '#19233E'
const DARK_BLUE_6 = '#1F2940'
const DARK_BLUE_7 = '#252B3C'
const DARK_BLUE_8 = '#1D2730'
const RICH_BLACK = '#0A0F1D'
const GREEN = '#1DDE2A'
const GREEN_2 = '#D0FFE3'
const GREEN_3 = '#44BE4C'
const RED = '#FF4646'
const RED_2 = '#FF9696'
const RED_3 = '#A73535'
const GREY = '#BCBCBC'
const GREY_2 = '#CFCFCF'
const GREY_3 = '#C9C7C7'
const GREY_4 = '#95A0BC'
const LIGHT_GREY = '#F3F3F3'
const GRANITE_GREY = '#606060'
const GREY_DARK = '#1F2B4A'
const GREY_DARK_2 = '#252D44'
const GREY_LIGHT = '#384566'
const SLATE_GRAY = '#7B8C98'
const LIGHT_BLUE = '#30D8EF'
const LIGHT_BLUE_2 = '#C5CBDC'
const LIGHT_BLUE_3 = '#6B82BE'
const LIGHT_BLUE_4 = '#80A4FF'
const GREY_BLUE = '#4F5880'
const GREY_BLUE_2 = '#303958'
const GREY_BLUE_3 = '#3A518D'
const GREY_BLUE_4 = '#374361'
const GREY_BLUE_5 = '#546796'
const GREY_BLUE_6 = '#242C47'
const GREY_BLUE_7 = '#545D7D'
const EU_BLUE = '#0052A0'

const GRADIENT_FROM = '#3C4B73'
const GRADIENT_TO = '#141C31'
const ROUND_GRADIENT_FROM = transparentize(0.3, '#1F2947')
const ROUND_GRADIENT_TO = '#12192B'

// Fonts
const FONT_SIZE_BASE = 16

export const px2rem = (size: number, base = FONT_SIZE_BASE) => `${size / base}rem`

const theme = {
  spacer: px2rem(8),
  colors: {
    primary: YELLOW,
    primary2: YELLOW_DARK,
    primary3: YELLOW_LIGHT,
    secondary: GREY_DARK,
    secondary2: GREY_LIGHT,
    secondary3: GREY_BLUE,
    secondary4: GREY_BLUE_2,
    secondary5: GRANITE_GREY,
    secondary6: GREY_BLUE_3,
    secondary7: GREY_BLUE_4,
    secondary8: GREY_BLUE_5,
    secondary9: GREY_4,
    secondary10: LIGHT_GREY,
    secondary11: GREY_BLUE_7,
    text: WHITE,
    text2: DARK_BLUE_8,
    textInverted: BLACK,
    background: BLACK_2,
    background2: DARK_BLUE,
    background3: DARK_BLUE_6,
    background4: BLUE,
    background5: GREY_DARK_2,
    background6: RICH_BLACK,
    background7: BLUE_2,
    background8: GREY_BLUE_6,
    background9: DARK_BLUE_5,
    background10: DARK_BLUE_3,
    background11: DARK_BLUE_7,
    background12: BLACK_4,
    positive: GREEN,
    positive2: GREEN_2,
    positive3: GREEN_3,
    negative: RED,
    negative2: RED_2,
    negative3: RED_3,
    auxiliary: EU_BLUE,
    auxiliary2: LIGHT_BLUE,
    auxiliary3: SLATE_GRAY,
    auxiliary4: LIGHT_BLUE_2,
    auxiliary5: GREY,
    auxiliary6: GREY_2,
    auxiliary7: GREY_3,
    auxiliary8: LIGHT_BLUE_3,
    auxiliary9: DARK_BLUE_4,
    warning: ORANGE,
    warning2: ORANGE_2,
  },
  textColors: {
    // Parity with colors
    primary: 'textInverted',
    primary2: 'textInverted',
    primary3: 'textInverted',
    secondary: 'text',
    secondary2: 'text',
    secondary3: 'text',
    secondary4: 'text',
    secondary5: 'text',
    secondary6: 'text',
    secondary7: 'text',
    secondary8: 'text',
    secondary9: 'textInverted',
    secondary10: 'textInverted',
    secondary11: 'text',
    text: 'textInverted',
    text2: 'text',
    textInverted: 'text',
    background: 'text',
    background2: 'text',
    background3: 'text',
    background4: 'text',
    background5: 'text',
    background6: 'text',
    background7: 'text',
    background8: 'text',
    background9: 'text',
    background10: 'text',
    background11: 'text',
    background12: 'text',
    positive: 'textInverted',
    positive2: 'positive',
    positive3: 'text',
    negative: 'textInverted',
    negative2: 'negative',
    negative3: 'textInverted',
    auxiliary: 'text',
    auxiliary2: 'textInverted',
    auxiliary3: 'textInverted',
    auxiliary4: 'textInverted',
    auxiliary5: 'textInverted',
    auxiliary6: 'textInverted',
    auxiliary7: 'textInverted',
    auxiliary8: 'textInverted',
    auxiliary9: 'text',
    warning: 'textInverted',
    warning2: 'warning',
  },
  overlays: {
    articleAuthor: transparentize(0.92, WHITE),
    navbar: transparentize(0.2, DARK_BLUE_2),
    transparentNavbar: transparentize(0.8, DARK_BLUE_2),
    navbarNoBlur: transparentize(0.03, DARK_BLUE_2),
    modal: transparentize(0.2, DARK_BLUE_3),
    section: `188deg, ${GRADIENT_FROM} 0%, ${GRADIENT_TO} 96%`, // Copied from figma, will be adjusted
    glow: `circle, ${ROUND_GRADIENT_FROM} 25%, ${ROUND_GRADIENT_TO} 45%`,
    mobileGlow: `circle, ${ROUND_GRADIENT_FROM} 25%, ${ROUND_GRADIENT_TO} 80%`,
    headline: 'rgba(11, 16, 29, 0.70)',
    mobileHeadline: `183deg, ${transparentize(1, LIGHT_BLUE_4)} 20%, ${transparentize(0.13, DARK_BLUE)} 76.74%`,
    previousSlide: `90deg, ${RICH_BLACK} 0%, rgba(10, 15, 29, 0) 100%`,
    nextSlide: `270deg, ${RICH_BLACK} 0%, rgba(10, 15, 29, 0) 100%`,
    hero: `180deg, ${transparentize(0.3, BLACK_3)} 100%, ${transparentize(0.3, BLACK_3)} 100%`,
    mobileHero: `180deg, ${transparentize(0.2, BLACK_3)} 100%, ${transparentize(0.2, BLACK_3)} 100%`,
    footer: transparentize(0.3, BLACK_3),
    checkModal: `transparent, ${RICH_BLACK} ${px2rem(20)}, ${RICH_BLACK} calc(100% - ${px2rem(20)}), transparent 100%`,
  },
  fonts: {
    family: 'Open Sans Variable',
    sizes: {
      40: {
        desktop: px2rem(40),
        mobile: px2rem(36),
        lineHeight: 1.2,
      },
      36: {
        desktop: px2rem(36),
        mobile: px2rem(30),
        lineHeight: 1.5,
      },
      32: {
        desktop: px2rem(32),
        mobile: px2rem(26),
        lineHeight: 1.5,
      },
      30: {
        desktop: px2rem(30),
        mobile: px2rem(24),
        lineHeight: 1.5,
      },
      24: {
        desktop: px2rem(24),
        mobile: px2rem(20),
        lineHeight: 1.5,
      },
      22: { // Compatibility
        desktop: px2rem(22),
        mobile: px2rem(20),
        lineHeight: 1.5,
      },
      20: { // Compatibility
        desktop: px2rem(20),
        mobile: px2rem(18),
        lineHeight: 1.5,
      },
      18: {
        desktop: px2rem(18),
        mobile: px2rem(16),
        lineHeight: 1.5,
      },
      16: {
        desktop: px2rem(16),
        mobile: px2rem(14),
        lineHeight: 1.5,
      },
      14: {
        desktop: px2rem(14),
        mobile: px2rem(12),
        lineHeight: 'normal',
      },
      12: {
        desktop: px2rem(12),
        mobile: px2rem(12),
        lineHeight: 'normal',
      },
      10: { // Compatibility
        desktop: px2rem(10),
        mobile: px2rem(8),
        lineHeight: 1.5,
      },
    },
    size: { // Compatibility
      xxxs: px2rem(8),
      xxs: px2rem(10),
      xs: px2rem(12),
      sm: px2rem(14),
      md: px2rem(18),
      lg: px2rem(20),
      xl: px2rem(30),
      xxl: px2rem(40),
      xxxl: px2rem(60),
    },
    weight: {
      regular: '400',
      semiBold: '600',
      bold: '700',
    },
    lineHeight: {
      xxs: '1.5',
      xs: '1.5',
      sm: '1.5',
      md: '1.5',
      lg: '1.5',
      xl: '1.5',
      xxl: '1.2',
    },
    fontStyle: {
      normal: 'normal',
      italic: 'italic',
    },
  },
  responsive: {
    breakpoints: {
      mobile: 768,
      tablet: 992,
      desktop: 1440,
    },
    zoom: {
      minimalViewport: 360,
      maximalViewport: 1920,
      fontSizeBase: FONT_SIZE_BASE,
    },
    container: {
      sm: {
        desktop: px2rem(560),
        tablet: px2rem(560),
        mobile: px2rem(400, 12),
      },
      md: {
        desktop: px2rem(960),
        tablet: px2rem(960),
        mobile: px2rem(720, 12),
      },
    },
  },
  animation: '.2s ease-in',
  border: {
    default: '1px solid',
    bold: '2px solid',
    radius: {
      xxs: px2rem(6),
      xs: px2rem(8),
      sm: px2rem(10),
      md: px2rem(20),
      lg: px2rem(30),
      xl: px2rem(40),
    },
  },
  backdropBlur: 'blur(34px)',
  shadow: {
    cookieBar: `0 -${px2rem(4)} ${px2rem(12)} 0 rgba(0, 0, 0, 0.15)`,
    cookieBarMobile: `0 -${px2rem(4)} ${px2rem(9)} 0 rgba(0, 0, 0, 0.45)`,
    menu: `0 ${px2rem(8)} ${px2rem(16)}`,
    newsCard: `0 ${px2rem(4)} ${px2rem(28)} 0 rgba(0, 0, 0, 0.35)`,
    paper: `0 ${px2rem(4)} ${px2rem(4)} rgba(0, 0, 0, 0.25)`,
    whyCard: `0 ${px2rem(10)} ${px2rem(34)} 0 rgba(0, 0, 0, 0.25)`,
    hover: `0 0 ${px2rem(18)}`,
    tag: `${px2rem(10)} ${px2rem(8)} ${px2rem(14)} 0 rgba(0, 0, 0, 0.4)`,
    text: `0.1em 0.1em ${BLACK}`,
    textInverted: `0.1em 0.1em ${transparentize(0.9, BLACK)}`,
    header: `0 0 ${px2rem(16)} rgba(0, 0, 0, 0.1)`,
    company: `${px2rem(8)} 0 ${px2rem(8)} rgba(0, 0, 0, 0.1)`,
    vehicleButton: `0 ${px2rem(9)} ${px2rem(10)} ${px2rem(-8)} rgba(0, 0, 0, 0.64)`,
    input: `0 0 ${px2rem(6)} ${LIGHT_BLUE_3}`,
    filler: `0 ${px2rem(3)} ${px2rem(5)} rgba(0, 0, 0, 0.15)`,
    invertedFiller: `0 0 ${px2rem(5)} rgba(0, 0, 0, 0.15)`,
  },
  zIndex: {
    menu: 500,
  },
} as const

export type Theme = typeof theme
export type Sizes = keyof typeof theme.fonts.sizes
export type FontSizes = keyof typeof theme.fonts.size
export type ContainerSizes = keyof typeof theme.responsive.container
export type FontStyle = keyof typeof theme.fonts.fontStyle
export type Weight = keyof typeof theme.fonts.weight
export type LineHeight = keyof typeof theme.fonts.lineHeight
export type Breakpoint = keyof typeof theme.responsive.breakpoints
export type Color = keyof typeof theme.colors
export type TextColor = keyof typeof theme.textColors
export type Radius = keyof typeof theme.border.radius

export default theme
