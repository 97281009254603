import {extendApi} from '@anatine/zod-openapi'
import {addDays, addMonths, getYear, isAfter, isBefore, isSameDay, subDays, subYears} from 'date-fns'
import {keys} from 'lodash'
import z from 'zod'
import {ALL_BODYWORKS} from '../../constants/bodyworks'
import colors from '../../constants/colors'
import {ALL_EXTENDED_FUELS, ALL_FUELS} from '../../constants/fuels'
import {ALL_INSURANCES} from '../../constants/insurances'
import {ALL_LEGAL_FORMS} from '../../constants/legalFormTypes'
import {ALL_PAYMENT_FREQUENCIES} from '../../constants/paymentFrequencies'
import {ALL_TELEPHONE_CODES} from '../../constants/telephoneCodes'
import {ALL_TRANSMISSIONS} from '../../constants/transmissions'
import {ALL_VEHICLE_IDENTIFIERS} from '../../constants/vehicleIdentifiers'
import {ALL_VEHICLE_TYPES, SUPPORTED_VEHICLE_TYPES} from '../../constants/vehicleTypes'
import {ALL_VEHICLE_USAGES, SUPPORTED_VEHICLE_USAGES} from '../../constants/vehicleUsages'
import {DEFAULT_BEGIN_DATE_MAX_MONTHS, validateBornNumber} from '../utils'
import {boolean, createEnum, nonNegativeInteger, positiveInteger, standardString, uuid} from './types'


const MIN_PRODUCTION_YEAR = 1974

// General

export const email = () => extendApi(z.string().email(), {example: 'example@example.com'})

export const telephoneCode = () => createEnum(ALL_TELEPHONE_CODES)

export const telephoneWithoutPrefix = () => extendApi(
  z.string()
    .regex(/^(?!421)[1-9]\d{8}$/),
  {example: '903123456'},
)

export const telephoneWithPrefix = () => extendApi(
  z.string()
    .regex(/^\+[1-9]{1,5}[1-9]\d{4,10}$/),
  {example: '+421903123456'},
)

export const date = () => extendApi(
  z.string()
    .trim()
    .regex(/^\d{4}-(0[1-9]|1[0-2])-([0][1-9]|[12][0-9]|[3][01])$/),
  {example: '1980-03-24'},
)

export const contact = z.object({
  email: email(),
  message: z.string().min(1),
})
export type Contact = z.infer<typeof contact>

const logfileUuid = uuid().brand<'LogfileUuid'>()
export type LogfileUuid = z.infer<typeof logfileUuid>

const publicFileUuid = uuid().brand<'PublicFileUuid'>()
export type PublicFileUuid = z.infer<typeof publicFileUuid>

const emailAttemptUuid = uuid().brand<'EmailAttemptUuid'>()
export type EmailAttemptUuid = z.infer<typeof emailAttemptUuid>

const contactEmailUuid = uuid().brand<'ContactEmailUuid'>()
export type ContactEmailUuid = z.infer<typeof contactEmailUuid>

const telephoneUuid = uuid().brand<'TelephoneUuid'>()
export type TelephoneUuid = z.infer<typeof telephoneUuid>

const vehicleUuid = uuid().brand<'VehicleUuid'>()
export type VehicleUuid = z.infer<typeof vehicleUuid>

// Vehicle

export const fuel = () => createEnum(ALL_FUELS)

export const extendedFuel = () => createEnum(ALL_EXTENDED_FUELS)

export const color = () => createEnum(keys(colors))

export const transmission = () => createEnum(ALL_TRANSMISSIONS)

export const bodywork = () => createEnum(ALL_BODYWORKS)

export const vehicleType = () => createEnum(SUPPORTED_VEHICLE_TYPES)

export const extendedVehicleType = () => createEnum(ALL_VEHICLE_TYPES)

export const vehicleUsage = () => createEnum(SUPPORTED_VEHICLE_USAGES)

export const extendedVehicleUsage = () => createEnum(ALL_VEHICLE_USAGES)

export const licensePlate = () => extendApi(
  z.string().transform((val) => val.toUpperCase().replace(' ', ''))
    .pipe(z.string().length(7).regex(/^[A-Z0-9]+$/)),
  {example: 'BA111EX'},
)

export const vin = () => extendApi(
  z.string().transform((val) => val.toUpperCase())
    .pipe(z.string().length(17).regex(/^[A-HJ-NPR-Z0-9]{17}$/)), // ISO 3779
  {example: 'AAAAAAAAAA0000000'},
)

export const registrationDate = () => extendApi(
  date()
    .refine(
      (date) => isBefore(new Date(date), new Date()) || isSameDay(new Date(date), new Date()),
      {message: 'Dátum registrácie vozidla môže byť maximálne dnešný'},
    )
    .refine(
      (date) => isAfter(new Date(date), new Date(MIN_PRODUCTION_YEAR, 0)),
      {message: `Rok registrácie vozidla môže byť minimálne ${MIN_PRODUCTION_YEAR}`},
    ),
  {example: '2023-03-24'},
)

export const productionDate = () => extendApi(
  date()
    .refine(
      (date) => isBefore(new Date(date), new Date()) || isSameDay(new Date(date), new Date()),
      {message: 'Dátum výroby vozidla môže byť maximálne dnešný'},
    )
    .refine(
      (date) => isAfter(new Date(date), new Date(MIN_PRODUCTION_YEAR, 0)),
      {message: `Rok výroby vozidla môže byť minimálne ${MIN_PRODUCTION_YEAR}`},
    ),
  {example: '2023-03-24'},
)

export const registrationNumber = () => extendApi(
  z.string()
    .length(8)
    .regex(/^[A-Z]{2}[A-Z0-9][0-9]{5}$/),
  {example: 'AB123456'},
)

export const vehicleIdentificationSome =
  z.object({
    licensePlate: licensePlate(),
    vin: vin().optional(),
  }).or(z.object({
    licensePlate: licensePlate().optional(),
    vin: vin(),
  }))
export type VehicleIdentificationSome = z.infer<typeof vehicleIdentificationSome>

export const isVehicleIdentificationSome = (
  vehicleIdentification: Record<string, unknown>
): vehicleIdentification is VehicleIdentificationSome => {
  return Boolean(vehicleIdentification.vin || vehicleIdentification.licensePlate)
}

export const vehicleIdentificationOptional = z.object({
  licensePlate: licensePlate().optional(),
  vin: vin().optional(),
})
export type VehicleIdentificationOptional = z.infer<typeof vehicleIdentificationOptional>

export const vehicle = {
  type: vehicleType(),
  brand: standardString('bmw'),
  model: standardString('rad5'),
  weight: positiveInteger({max: 3500, example: 2320}),
  volume: nonNegativeInteger({max: 10000, example: 2926}),
  power: positiveInteger({max: 1000, example: 159}),
  fuel: fuel(),
  seats: positiveInteger({max: 100, example: 5}),
  productionDate: productionDate(),
  productionYear: positiveInteger({max: getYear(new Date()), min: MIN_PRODUCTION_YEAR, example: 2021}),
  registrationDate: registrationDate(),
  registrationNumber: registrationNumber(),
  usage: vehicleUsage(),
  color: color(),
  odometer: positiveInteger({max: 10000000, example: 10000}),
  transmission: transmission(),
  bodywork: bodywork(),
}

// Person

export const legalForm = () => createEnum(ALL_LEGAL_FORMS)

export const companyNumber = () => extendApi(
  z.string()
    .trim()
    .min(6)
    .max(12)
    .regex(/^[0-9]+$/),
  {example: '12345678'},
)

export const bornNumber = () => extendApi(
  z.string()
    .trim()
    .regex(/^(\d{2})([05][1-9]|[16][0-2])([0][1-9]|[12][0-9]|[3][01])(\d{3,4})$/)
    .refine(validateBornNumber, {
      message: 'Neplatné rodné číslo',
    }),
  {example: 6655159995},
)

const isAtLeast18YearsOld = (date: string) => {
  const eighteenYearsAgo = getYear(subYears(new Date(), 18))
  const birthdayYear = getYear(new Date(date))
  return eighteenYearsAgo - birthdayYear >= 0
}

const isBornAtLeastIn1900 = (date: string) => {
  const nineteenHundred = new Date('1900-01-01')
  const birthday = new Date(date)
  return isAfter(birthday, nineteenHundred) || isSameDay(birthday, nineteenHundred)
}

export const birthday = () => extendApi(
  date()
    .refine(isAtLeast18YearsOld, {
      message: 'Osoba musí mať aspoň 18 rokov',
    })
    .refine(isBornAtLeastIn1900, {
      message: 'Rok narodenia musí byť vyšší ako 1900',
    }),
  {example: '2005-03-23'},
)

export const streetNo = () => extendApi(
  z.string()
    .trim()
    .min(1)
    .max(10)
    .regex(/^[1-9].*$/),
  {example: '13'},
)

export const telephoneOptional = z.object({
  telephoneCode: telephoneCode(),
  telephoneNumber: telephoneWithoutPrefix(),
}).or(z.object({
  telephoneCode: z.undefined(),
  telephoneNumber: z.undefined(),
}))

export const name = () => extendApi(
  z.string()
    .trim()
    .min(1)
    .max(50)
    .regex(/^[^.,;]*$/),
  {example: 'Peter'},
)

export const idNumber = () => extendApi(
  z.string()
    .trim()
    .regex(/^([A-Z]{2}\d{6,7}|[A-Z]\d{7}|\d{7})$/),
  {example: 'AB123456'},
)

export const person = {
  legalForm: legalForm(),
  zipCode: standardString('93101'),
  city: standardString('Šamorín'),
  street: standardString('Pekná'),
  streetNo1: streetNo(),
  streetNo2: streetNo(),
  birthday: birthday(),
  bornNumber: bornNumber(),
  firstName: name(),
  lastName: name(),
  idNumber: idNumber(),
  drivingLicenceYear: positiveInteger({max: getYear(new Date()), example: 2000}),
  ztp: boolean().optional(),
  companyNumber: companyNumber(),
  companyName: standardString('Firma'),
}

// PZP

export const insurance = () => createEnum(ALL_INSURANCES)

export const damageClaimsCount = positiveInteger().optional()

export const paymentFrequency = () => createEnum(ALL_PAYMENT_FREQUENCIES)

export const beginDate = (
  {maxDays, maxMonths}: {maxDays?: number, maxMonths?: number} = {maxMonths: DEFAULT_BEGIN_DATE_MAX_MONTHS}
) => date()
  .refine(
    (date) => isAfter(new Date(date), subDays(new Date(), 1)),
    {message: 'Dátum musí byť minimálne dnešný'},
  )
  .refine(
    (date) => !maxDays || isBefore(new Date(date), addDays(new Date(), maxDays)),
    {message: `Dátum môže byť maximálne ${maxDays} dní od dnes`},
  )
  .refine(
    (date) => !maxMonths || isBefore(new Date(date), addMonths(new Date(), maxMonths)),
    {message: `Dátum môže byť maximálne ${maxMonths} mesiacov od dnes`},
  )

export const packagePrice = z.object({
  term: z.number().optional(),
  total: z.number().optional(),
})

export const packageOffer = z.object({
  insurance: insurance(),
  packageCoverage: z.string(),
  yearly: packagePrice,
  semiAnnually: packagePrice,
  quarterly: packagePrice,
})
export type PackageOffer = z.infer<typeof packageOffer>

export const vehicleIdentifiers = () => createEnum(ALL_VEHICLE_IDENTIFIERS)
