export const VEHICLE_USAGE_BASIC = 'basic'
export const VEHICLE_USAGE_RENTAL = 'rental'
export const VEHICLE_USAGE_TAXI = 'taxi'
export const VEHICLE_USAGE_DRIVING_SCHOOL = 'drivingSchool'
export const VEHICLE_USAGE_AMBULANCE = 'ambulance'
export const VEHICLE_USAGE_DANGEROUS_CARGO = 'dangerousCargo'
export const VEHICLE_USAGE_HISTORIC = 'historic'
export const VEHICLE_USAGE_SPECIAL_REG_NUMBER = 'specialRegNumber'
export const VEHICLE_USAGE_HISTORIC_SPECIAL_REG_NUMBER = 'historicSpecialRegNumber'
export const VEHICLE_USAGE_RIGHT_OF_WAY = 'rightOfWay'
export const VEHICLE_USAGE_INTERNATIONAL_TRANSPORT = 'internationalTransport'
export const ALL_VEHICLE_USAGES = [
  VEHICLE_USAGE_BASIC,
  VEHICLE_USAGE_RENTAL,
  VEHICLE_USAGE_TAXI,
  VEHICLE_USAGE_DRIVING_SCHOOL,
  VEHICLE_USAGE_AMBULANCE,
  VEHICLE_USAGE_DANGEROUS_CARGO,
  VEHICLE_USAGE_HISTORIC,
  VEHICLE_USAGE_SPECIAL_REG_NUMBER,
  VEHICLE_USAGE_HISTORIC_SPECIAL_REG_NUMBER,
  VEHICLE_USAGE_RIGHT_OF_WAY,
  VEHICLE_USAGE_INTERNATIONAL_TRANSPORT,
] as const

export type VehicleUsage = typeof ALL_VEHICLE_USAGES[number]

export const SUPPORTED_VEHICLE_USAGES = [
  VEHICLE_USAGE_BASIC,
] as const
