import {extendApi} from '@anatine/zod-openapi'
import invariant from 'invariant'
import {first, mapValues} from 'lodash'
import type {
  Primitive, ZodRawShape, ZodTypeAny, ZodObject, ZodDiscriminatedUnionOption, ZodDiscriminatedUnion, ZodUnion,
} from 'zod'
import z from 'zod'


export const id = () => extendApi(
  z.unknown().transform((value) => value && Number(value)).pipe(z.number().int().min(1)),
  {example: '1'},
)

export const uuid = () => extendApi(
  z.string().uuid(),
  {example: 'e917c393-fd85-41e5-a6b2-60348515b127'},
)

export const timestamp = () => extendApi(z.coerce.number().int().min(1), {example: '1561161600'})

export const standardString = (example?: string) => extendApi(z.string().min(1).max(255), {example})

export const positiveInteger = (opts?: {example?: number, max?: number, min?: number}) => extendApi(
  z.unknown().transform((value) => value &&
    Number(value)).pipe(z.number().int().positive().max(opts?.max || Infinity).min(opts?.min || 1)),
  {example: opts?.example},
)

export const number = (example?: number) => extendApi(
  z.unknown().transform((value) => value && Number(value)).pipe(z.number()),
  {example},
)

export const nonNegativeInteger = (opts?: {example?: number, max?: number}) => extendApi(
  z
    .unknown()
    .transform((value) => value && Number(value)).pipe(z.number().int().nonnegative().max(opts?.max || Infinity)),
  {example: opts?.example},
)

export const boolean = (example?: boolean) => extendApi(
  z.unknown().transform((value) => {
    if (value === 'true') return true
    if (value === 'false') return false
    return value
  }).pipe(z.boolean()),
  {example: example || true},
)

export const object = <T extends ZodRawShape>(keys: T) => z.object(keys)

export const optional = <T extends ZodTypeAny>(schema: T) => z.optional(schema)

export const withAnyAdditional = <T extends ZodTypeAny>(schema: T) => schema.and(z.object({}).passthrough())

export const nullable = <T extends ZodTypeAny>(schema: T) => z.nullable(schema)

export const optionalObject = <T extends ZodRawShape>(keys: T) => (
  z.object(keys).partial()
)

export const array = <T extends ZodTypeAny>(schema: T) => z.array(schema)

export const collection = <T extends ZodRawShape>(schema: T) => array(object(schema))

export const literal = <T extends Primitive>(value: T) => extendApi(z.literal(value), {example: value})

export const createEnum = <U extends string>(values: Readonly<U[]>) => {
  invariant(values.length > 0, 'Enum must have at least one possible value')

  const nonEmptyValues = [values[0], ...values.slice(1)] as const
  return extendApi(z.enum(nonEmptyValues), {example: first(values)})
}

const jsonLiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
type JSONLiteral = z.infer<typeof jsonLiteralSchema>
export type JSONValue = JSONLiteral | { [key: string]: JSONValue | undefined } | JSONValue[]
export const json = (): z.ZodType<JSONValue> => z.lazy(() => (
  z.union([jsonLiteralSchema, z.array(json()), z.record(json().or(z.undefined()))])
))

export const mappedObject = <T extends ZodRawShape, U extends ZodTypeAny>
  (shape: T, schema: () => U): ZodObject<{[K in keyof T]: U}> => (
    z.object(mapValues(shape, schema))
  )

export const mapUnion = <
  D extends string,
  S extends ZodDiscriminatedUnionOption<D>,
  T extends ZodDiscriminatedUnion<D, [S, ...S[]]>,
  O extends ZodRawShape,
  U extends ZodUnion<[ZodObject<O>, ...ZodObject<O>[]]>,
  N extends ZodTypeAny,
>(union: T | U, schema: () => N) => mappedObject(union.options[0].shape, schema)
